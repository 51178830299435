@use '@angular/material' as mat;
@use 'sass:map';

$toggle-group-border-radius: 5px !default;
$toggle-group-gap: 3px !default;
$toggle-button-min-width: 96px !default;
$toggle-button-padding: 0 15px !default;
$toggle-button-font-size: 12px !default;
$toggle-button-font-weight: 700 !default;
$toggle-button-border-radius: 5px !default;
$toggle-button-height: 28px !default;
$toggle-button-icon-size: 18px !default;
$toggle-button-content-margin: 8px !default;
$toggle-button-ripple-opacity: 0.1 !default;

@mixin core() {
  .app-button-toggle {
    display: flex;
    align-items: center;
    overflow: hidden;
    background: transparent;
    border: 0 !important;
    border-radius: $toggle-button-border-radius;
    min-width: $toggle-button-min-width;
    transition: color 200ms, background-color 200ms;

    .mat-icon, [class^="icon-"], [class*=" icon-"] {
      margin-right: $toggle-button-content-margin;
      height: $toggle-button-icon-size;
      font-size: $toggle-button-icon-size;
      width: $toggle-button-icon-size;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-toggle-label-content {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: $toggle-button-height;
      padding: $toggle-button-padding;
    }
  }

  .app-button-toggle + .app-button-toggle {
    margin: 0 0 0 3px;
  }

  .app-button-toggle-group {
    padding: $toggle-group-gap;
    border-radius: $toggle-group-border-radius;
    border: 0;
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    &.mat-button-toggle-vertical {
      .app-button-toggle + .app-button-toggle {
        margin: 3px 0 0 0;
      }
    }
  }
}

@mixin color($theme, $fg, $bg) {
  .app-button-toggle {
    color: map.get($fg, 'secondary');

    .mat-ripple-element {
      background-color: rgba(map.get($fg, 'accent'), 0.1);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    &.mat-button-toggle-checked {
      background: white;
      color: map.get($fg, 'accent');
    }
  }

  .app-button-toggle-group {
    background: map.get($bg, 'light');
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .app-button-toggle {
    @include mat.m2-typography-level($typography-config, 'caption');
    font-weight: 700;
  }
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
