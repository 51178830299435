@use '@angular/material' as mat;
@use 'sass:map';

@mixin core() {
  app-line {
    position: absolute;
    inset: 0;

    .domain {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;
      overflow: visible;
    }

    .tick line {
      stroke-width: 1;
      stroke-dasharray: 4;
    }

    .series {
      fill: none;
    }

    circle,
    path {
      transition-property: opacity, stroke;
      transition-duration: .1s;
    }
  }
}

@mixin color($theme, $fg, $bg) {
  app-line {
    .y-axis, .y-axis-right, .y-axis-label {
      color: map.get($fg, secondary);
    }

    .tick line {
      color: map.get($fg, border-stroke);
    }

    .series path.disabled {
      stroke: map.get($fg, border-stroke);
      filter: none;
    }

    .no-data {
      color: map.get($fg, secondary);
    }
  }
}

@mixin typography($theme, $fb, $bg) {
  $typography-config: mat.m2-get-typography-config($theme);

  app-line {
    .y-axis .tick, .y-axis-right .tick {
      @include mat.m2-typography-level($typography-config, 'body-2')
    }

    .x-axis .tick {
      @include mat.m2-typography-level($typography-config, 'subtitle-2')
    }
  }
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fb, $bg);
  }
}
