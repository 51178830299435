@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:math';
@use '../palette' as palette;
@use '../mixins' as mixins;

$chip-border-radius: 4px !default;
$chip-padding: 0 10px !default;
$chip-min-height: 24px !default;
$chip-remove-size: 6px !default;

$chip-menu-border-radius: 8px !default;
$chip-menu-padding: 4px 12px !default;
$chip-menu-max-width: 320px !default;

@mixin core() {
  .app-chip {
    border-radius: $chip-border-radius;
    --mdc-chip-container-height: #{$chip-min-height};
    min-height: $chip-min-height;

    &.app-chip-border {
      border: 1px solid currentColor;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
    .mat-chip-remove {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      padding: 0;
      background: transparent;
      opacity: 1;

      i {
        font-size: $chip-remove-size;
        width: $chip-remove-size;
        height: $chip-remove-size;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
    &.mat-mdc-standard-chip:not(.mat-chip-disabled):active {
      box-shadow: none;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
    &[disableRipple]::after,
    &[disableRipple] .mat-mdc-chip-focus-overlay {
      display: none;
    }

    &-menu {
      //margin: 4px 0 4px 8px;
      max-width: $chip-menu-max-width;
      min-height: 0;
      padding: $chip-menu-padding;
      border-radius: $chip-menu-border-radius;
      @include mixins.elevation()
    }
  }
}

@mixin color($theme, $fg, $bg) {
  .app-chip {
    @each $name, $bg in map.get(palette.$chips, bg) {
      &.app-chip-#{"" + $name} {
        background: $bg;
        color: map.get(map.get(palette.$chips, fg), $name);
        --mdc-chip-label-text-color: #{map.get(map.get(palette.$chips, fg), $name)};

        &.app-chip-border {
          border-color: map.get(map.get(palette.$chips, border), $name);
        }
      }

      &-#{"" + $name}-menu {
        background: map.get(map.get(palette.$chips, border), $name);
      }
    }
  }
}

@mixin typography($theme, $fb, $bg) {
  $typography-config: mat.m2-get-typography-config($theme);

  .app-chip {
    --mdc-chip-label-text-size: #{mat.m2-font-size($typography-config, 'caption')};
    --mdc-chip-label-text-line-height: #{mat.m2-line-height($typography-config, 'caption')};
    --mdc-chip-label-text-font: #{mat.m2-font-family($typography-config, 'caption')};

    --mdc-chip-label-text-tracking: #{mat.m2-letter-spacing($typography-config, 'caption')};
    --mdc-chip-label-text-weight: 700;

    &.app-chip-general {
      --mdc-chip-label-text-weight: 400;
    }
  }
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fb, $bg);
  }
}
