@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme, $fg, $bg) {
  $color-config: mat.m2-get-color-config($theme);

  $cardBackground: map.get($bg, 'card');
  $secondaryText: map.get($fg, 'subdued');
  $divider: map.get($fg, 'border-stroke');

  app-single-metric {
    border-color: $divider
  }

  .metric-name {
    color: $secondaryText
  }

  .metric-delta-negative {
    color: map.get(mat.$m2-red-palette, 900);
    background: map.get(mat.$m2-red-palette, 50);
  }

  .metric-delta-positive {
    color: map.get(mat.$m2-green-palette, 900);
    background: map.get(mat.$m2-green-palette, 50);
  }
}

@mixin typography($theme, $fg, $bg) {
  $typography-config: mat.m2-get-typography-config($theme);

  .metric-name {
    @include mat.m2-typography-level($typography-config, 'caption');
  }

  .metric-delta {
    font-size: 10px;
  }

  .metric-delta-arrow {
    font-size: 5px;
  }

  .metric-loading,
  .metric-current {
    @include mat.m2-typography-level($typography-config, 'headline-6');
    line-height: 1;
  }
}

@mixin theme($theme, $fg, $bg) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme, $fg, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fg, $bg);
  }
}
