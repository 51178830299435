@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme, $fg, $bg) {
  $accent: map.get(mat.m2-get-color-config($theme), accent);

  app-select-like-menu {
    .menu-trigger {
      color: map.get($fg, secondary);
      border-color: map.get($fg, border-disable);

      i {
        color: map.get($fg, subdued);
      }

      &:hover, &:focus {
        border-color: mat.m2-get-color-from-palette($accent)
      }
    }
  }
}

@mixin typography($theme, $fb, $bg) {
  $typography-config: mat.m2-get-typography-config($theme);

  app-select-like-menu{
    .mat-mdc-select-trigger {
      @include mat.m2-typography-level($typography-config, caption);
    }
  }
}

@mixin theme($theme, $fb, $bg) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fb, $bg);
  }

  .select-overlay{
    margin-top: 1px;
  }
}
