@use 'palette' as palette;
@use 'components' as components;
@use 'common' as common;
@use '@angular/material' as mat;
@use '../app/app.theme' as app;

$typography: mat.m2-define-typography-config(
  $font-family: 'Cera Pro, "Helvetica Neue", sans-serif',
  $headline-5: mat.m2-define-typography-level(24px, 32px, 700),
  $headline-6: mat.m2-define-typography-level(20px, 24px, 700),
  $subtitle-1: mat.m2-define-typography-level(18px, 28px, 700),
  $body-1: mat.m2-define-typography-level(18px, 22px, 400),
  $subtitle-2: mat.m2-define-typography-level(14px, 22px, 700),
  $body-2: mat.m2-define-typography-level(14px, 22px, 400),
  $caption: mat.m2-define-typography-level(12px, 16px, 400),
  $button: mat.m2-define-typography-level(18px, 24px, 700),
  /* TODO(mdc-migration): No longer supported. Use `body-1` instead. $input: mat.define-typography-level(inherit, 1.125, 400), */
);

$primary: mat.m2-define-palette(palette.$orange, 800, 700, 900);
$accent: mat.m2-define-palette(palette.$blue, 700, 600, 800);

$theme: mat.m2-define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
  ),
  typography: $typography,
));

@include common.core();
@include mat.core();
@include mat.all-component-typographies($typography);
@include mat.all-component-themes($theme);

.color-scheme-light {
  @include common.theme($theme, palette.$foreground, palette.$background);
  @include components.theme($theme, palette.$foreground, palette.$background);
  @include app.theme($theme, palette.$foreground, palette.$background);
}

