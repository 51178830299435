$map: (
  0: 0px,
  1: 5px,
  2: 10px,
  3: 15px,
  4: 20px,
  5: 30px
);

@each $name, $value in $map {
  .m-#{$name} {
    margin: $value;
  }

  .my-#{$name} {
    margin-top: $value;
    margin-bottom: $value;
  }

  .mx-#{$name} {
    margin-left: $value;
    margin-right: $value;
  }

  .mt-#{$name} {
    margin-top: $value;
  }

  .mr-#{$name} {
    margin-right: $value;
  }

  .mb-#{$name} {
    margin-bottom: $value;
  }

  .ml-#{$name} {
    margin-left: $value;
  }

  .p-#{$name} {
    padding: $value;
  }

  .py-#{$name} {
    padding-top: $value;
    padding-bottom: $value;
  }

  .px-#{$name} {
    padding-right: $value;
    padding-left: $value;
  }

  .pt-#{$name} {
    padding-top: $value;
  }

  .pr-#{$name} {
    padding-right: $value;
  }

  .pb-#{$name} {
    padding-bottom: $value;
  }

  .pl-#{$name} {
    padding-left: $value;
  }
}

