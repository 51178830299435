@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme, $fg, $bg) {
  $color-config: mat.m2-get-color-config($theme);
  $accent: map.get($color-config, accent);

  .pack-list-item {
    background: map.get($accent, 50);
  }
}

@mixin typography($theme, $fg, $bg) {
  $typography-config: mat.m2-get-typography-config($theme);

  .pack-list-item i {
    font-size: 20px;
  }
}

@mixin theme($theme, $fg, $bg) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme, $fg, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fg, $bg);
  }
}
