@use '@angular/material' as mat;
@use 'sass:map';

$checkbox-border-width: 1px !default;
$checkbox-border-radius: 4px !default;
$checkbox-bg: white !default;
$checkbox-bg-disabled: white !default;
$checkbox-size: 24px !default;
$checkbox-inset: 3px !default;
$checkbox-inset: 3px !default;
$checkbox-mixedmark-gap: 5px !default;
$checkbox-checkmark-stroke-width: 3px !default;

@mixin core() {
  .app-menu-item {
    .mat-pseudo-checkbox {
      width: $checkbox-size;
      height: $checkbox-size;
      border-width: $checkbox-border-width;
      border-radius: $checkbox-border-radius;
    }
  }

  .app-checkbox {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-inner-container {
      width: $checkbox-size;
      height: $checkbox-size;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-frame {
      border-width: $checkbox-border-width;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-background, .mat-checkbox-frame {
      border-radius: $checkbox-border-radius;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-layout .mat-checkbox-label {
      line-height: $checkbox-size;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-mixedmark {
      width: calc(100% - #{$checkbox-mixedmark-gap * 2});
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-checkmark {
      top: $checkbox-inset;
      left: $checkbox-inset;
      right: $checkbox-inset;
      bottom: $checkbox-inset;
      width: $checkbox-size - $checkbox-inset * 2;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-checkmark-path {
      stroke-width: $checkbox-checkmark-stroke-width;
    }
  }
}

@mixin color($theme, $fg, $bg) {
  .app-menu-item {
    .mat-pseudo-checkbox {
      background: map.get($fg, 'white');
      border-color: map.get($fg, 'border-disable');
    }

    .mat-pseudo-checkbox-checked {
      border-width: 0;
    }

    .mat-pseudo-checkbox-disabled {
      background: map.get($bg, 'disabled');
      border-color: transparent;
    }
  }

  .app-checkbox {
    --mdc-checkbox-unselected-icon-color: #{map.get($fg, 'border-disable')};
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mat-checkbox-frame {
      background: map.get($fg, 'white');
      border-color: map.get($fg, 'border-disable');
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    &.mdc-checkbox--disabled .mat-checkbox-label {
      color: map.get($fg, 'disabled');
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    &.mdc-checkbox--disabled:not(.mat-checkbox-checked) .mdc-checkbox__background {
      --mdc-checkbox-disabled-unselected-icon-color: #{map.get($bg, 'disabled')};
      background-color: map.get($bg, 'disabled');
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    &.mdc-checkbox--disabled.mat-checkbox-checked .mdc-checkbox__background,
    &.mdc-checkbox--disabled.mat-checkbox-indeterminate .mdc-checkbox__background {
      --mdc-checkbox-disabled-unselected-icon-color: #{map.get($bg, 'disabled')};
      background-color: map.get($bg, 'disabled');
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
