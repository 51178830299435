$text-default: #202223;
$text-contrast: #ffffff;

$primary-violet: #425d91;
$primary-orange: #eda23c;
$primary-blue: #1a95ad;

$foreground: (
  default: $text-default,
  contrast: $text-contrast,
  accent: $primary-violet,
  tertiary: #80a2ac,
  success: #15AE65,
  heading: #000000,
  secondary: #8c9196,
  subdued: #6d7175,
  disabled: #8c9196,
  border-stroke: #dfe3e8,
  border-disable: #d2d5d8,
);

$background: (
  light: #f2f9fb,
  dark: #e5f3f7,
  card: #ffffff,
  disabled: #dfe3e8,
  tooltip: #1B315B,
  tooltip-title: #344D7D,
);

$chips: (
  bg: (
    general: #ffffff,
    enabled: #DFFBEF,
    success: #DFFBEF,
    disabled: #FFEDED,
    error: #FFEDED,
    transparent: transparent,
    consumable: #FFE7CE,
    non-consumable: #F1F3E0,
    seasonal: #F1F3E0,
    soft: #D1F2FA,
    hard: #D3DDF0,
    product: #D5E4ED,
  ),

  fg: (
    general: #000000,
    enabled: #406958,
    success: #406958,
    disabled: #954141,
    error: #954141,
    transparent: #000000,
    consumable: #C5813A,
    non-consumable: #838A48,
    seasonal: #838A48,
    soft: #1A95AD,
    hard: #425D91,
    product: #425D91,
  ),

  border: (
    general: #DFE3E8,
    product: #A9BCD1,
  )
);

$orange: (
  50: #fefce8,
  100: #fdf6c7,
  200: #fcf0a2,
  300: #fbeb7f,
  400: #f9e665,
  500: #f7e04e,
  600: #f6d14b,
  700: #f1ba43,
  800: $primary-orange,
  900: #e57e30,
  contrast: (
    50: $text-default,
    100: $text-default,
    200: $text-default,
    300: $text-default,
    400: $text-default,
    500: $text-default,
    600: $text-default,
    700: $text-default,
    800: $text-contrast,
    900: $text-contrast,
  )
);

$blue: (
  50: #f2f9fb,
  100: #b4eaf7,
  200: #83dcf1,
  300: #54cee9,
  400: #34c3e2,
  500: #26b9db,
  600: #21a9c8,
  700: $primary-blue,
  800: #148194,
  900: #0a5e68,
  contrast: (
    50: $text-default,
    100: $text-default,
    200: $text-default,
    300: $text-default,
    400: $text-default,
    500: $text-default,
    600: $text-default,
    700: $text-contrast,
    800: $text-contrast,
    900: $text-contrast,
  )
);
