@use '@angular/material' as mat;
@use 'sass:map';

$button-padding: 0 60px !default;
$button-min-width: 155px !default;
$button-margin: 0 !default;
$button-line-height: 45px !default;
$button-border-radius: 30px !default;
$button-border-width: 1px;

@mixin core() {
  //[mat-icon-button] {
  //  --mdc-icon-button-state-layer-size: 40px;
  //}
  .app-icon-button {
    //--mdc-icon-button-state-layer-size: 40px;
    //padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-wrapper {
      display: flex;
    }
  }

  .app-primary-button, .app-secondary-button {
    margin: $button-margin;
    min-width: $button-min-width;
    line-height: $button-line-height;
    height: auto;
    padding: $button-padding;
    border: {
      radius: $button-border-radius;
      width: $button-border-width;
      style: solid;
    };

    transition: background 200ms, color 200ms;
  }
}

@mixin color($theme, $fg, $bg) {
  $primary-palette: map.get(mat.m2-get-color-config($theme), 'primary');
  $primary: mat.m2-get-color-from-palette($primary-palette);
  $primary-contrast: mat.m2-get-color-from-palette($primary-palette, 'default-contrast');

  .app-primary-button {
    background-color: $primary;
    --mdc-text-button-label-text-color: #{$primary-contrast};
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    &:hover:not(:disabled) {
      background: transparent;
      color: map.get($fg, 'default');
    }
  }

  .app-primary-button, .app-secondary-button {
    border-color: $primary;
    --mat-mdc-button-persistent-ripple-color: #{$primary};
    .mat-ripple-element {
      --mat-mdc-button-ripple-color: #{$primary};
      opacity: .1;
      //background-color: $primary;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    &:disabled {
      border-color: transparent;
      color: map.get($fg, 'disabled');
      background: map.get($bg, 'disabled');
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
