$map: (
  none: 0px,
  sm: 4px,
  default: 8px,
  md: 20px,
  full: 50%
);

@each $name, $value in $map {
  .rounded-#{$name} {
    border-radius: $value;
  }
}
