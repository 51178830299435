@font-face {
  font-family: 'Elevatix Icons';
  src:  url('../assets/fonts/icons.woff?v=1') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'Elevatix Icons' !important;
  font-size: 24px;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-link:before {
  content: "\e927";
}
.icon-check:before {
  content: "\e920";
}
.icon-minus:before {
  content: "\e926";
}
.icon-calendar:before {
  content: "\e911";
}
.icon-arrow-up:before {
  content: "\e90e";
}
.icon-arrow-down:before {
  content: "\e90f";
}
.icon-expand:before {
  content: "\e912";
}
.icon-players:before {
  content: "\e900";
}
.icon-logout:before {
  content: "\e901";
}
.icon-user:before {
  content: "\e902";
}
.icon-add:before {
  content: "\e903";
}
.icon-campaigns:before {
  content: "\e904";
}
.icon-clam:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e906";
}
.icon-menu-collapse:before {
  content: "\e907";
}
.icon-dashboard:before {
  content: "\e908";
}
.icon-delete:before {
  content: "\e909";
}
.icon-dolphin:before {
  content: "\e90a";
}
.icon-edit:before {
  content: "\e90b";
}
.icon-email:before {
  content: "\e90c";
}
.icon-menu-expand:before {
  content: "\e90d";
}
.icon-minnow:before {
  content: "\e913";
}
.icon-non_payer::before {
  content: "\e914";
}
.icon-chevron-double-right:before {
  content: "\e915";
}
.icon-chevron-double-left:before {
  content: "\e916";
}
.icon-lock:before {
  content: "\e917";
}
.icon-eye-close:before {
  content: "\e918";
}
.icon-eye:before {
  content: "\e919";
}
.icon-chevron-down:before {
  content: "\e91a";
}
.icon-chevron-up:before {
  content: "\e91b";
}
.icon-packs:before {
  content: "\e91c";
}
.icon-products-directory:before {
  content: "\e91d";
}
.icon-segmentation:before {
  content: "\e91e";
}
.icon-appearance:before {
  content: "\e91f";
}
.icon-sort:before {
  content: "\e921";
}
.icon-whale:before {
  content: "\e922";
}
.icon-stores-manager:before {
  content: "\e923";
}
.icon-viber:before {
  content: "\e924";
}
.icon-telegram:before {
  content: "\e925";
}
.icon-ltv:before {
  content: "\e910";
}
