@use '@angular/material' as mat;
@use '../mixins' as mixins;
@use 'sass:map';

$card-border-radius: 8px;
$card-padding: 20px;

@mixin core() {
  .app-card {
    border-radius: $card-border-radius;
    padding: $card-padding;
    @include mixins.elevation();
    mat-card-content{
      padding: 0;
    }

    .mat-mdc-card-actions {
      margin: 0;

      button {
        margin: 0;

        & + button {
          margin-left: 20px;
        }
      }
    }

    & > .mat-mdc-card-actions:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin color($theme, $fg, $bg) {
  $color-config: mat.m2-get-color-config($theme);
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
