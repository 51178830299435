@use '@angular/material' as mat;
@use 'sass:map';

$input-min-height: 50px;
$input-element-height: 30px;
$input-padding: 8px 0 8px 0;
$input-border-radius: 8px;
$input-infix-gap: 8px;

@mixin core() {
  .app-form-field-container {
    --mat-form-field-container-text-size: 14px;
    --mat-select-trigger-text-size: 14px;
    display: flex;
    flex-direction: column;

    mat-label, label {
      margin: 0 0 2px 2px;
    }
  }

  .app-form-field {
    // TODO(mdc-migration): tmp solution for icon buttons in form-fields
    [mat-icon-button] {
      --mdc-icon-button-state-layer-size: 35px;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .mat-mdc-form-field-infix {
      padding: $input-padding;
      min-height: $input-min-height;
      display: flex;
      align-items: center;
      width: auto;
      border-top: 0;
    }

    .mat-mdc-input-element {
      height: $input-element-height;
    }

    .mat-mdc-text-field-wrapper{
      border-radius: $input-border-radius;
    }
    .mdc-text-field--outlined {
      padding: 0 8px 0 15px;
      --mdc-outlined-text-field-container-shape: #{$input-border-radius};
      top: 0;

      &:hover {
        --mdc-outlined-text-field-outline-width: 2px;
      }
    }

    .mat-mdc-form-field-icon-prefix {
      margin-right: $input-infix-gap;
    }

    .mat-mdc-form-field-icon-suffix {
      margin-left: $input-infix-gap;
    }


    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-outline-start, .mat-form-field-outline-end {
      min-width: $input-border-radius;
    }
  }

}

@mixin color($theme, $fg, $bg) {
  $color-config: mat.m2-get-color-config($theme);

  mat-label {
    color: map.get($fg, 'subdued')
  }

  .app-form-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
      color: map.get($fg, 'secondary');
    }
    .mdc-text-field--outlined {
      --mdc-outlined-text-field-outline-color: #{map.get($fg, 'border-stroke')};
      --mdc-outlined-text-field-hover-outline-color: #{map.get($fg, 'secondary')};
    }

    .mat-mdc-text-field-wrapper {
      background-color: map.get($bg, 'light');
      color: map.get($fg, 'strokes');
    }

    .mat-mdc-input-element:-webkit-autofill {
      box-shadow: 0 0 0 999px map.get($bg, 'light') inset
    }

    .mat-mdc-input-element::placeholder {
      color: map.get($fg, 'secondary');
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);

  .app-form-field {
    .mat-mdc-input-element[type='password']:not(:placeholder-shown) {
      font-family: sans-serif;
    }
  }
}

@mixin theme($theme, $fg, $bg) {
  @include core();

  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme, $fg, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
