@use '../../../../node_modules/@angular/material/index' as mat;
@use 'sass:map';

@mixin core() {
  .legacy-tooltip {
    position: absolute;
    z-index: 10;
    pointer-events: none;
    border-radius: 8px;
    min-width: 160px;
    overflow: hidden;

    &.left.top {
      transform: translate(-100%, -100%);
      border-bottom-right-radius: 0;
      margin-left: -5px;
      margin-top: -5px;
    }

    &-title {
      padding: 10px 20px;
    }

    &-values {
      padding: 8px 20px;
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 4px 0;

      &-name {
        flex: 1;
        margin-right: 16px;
      }

      &-indicator {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        flex-shrink: 0;
        background: currentColor;
        margin-right: 8px;
      }

      &-value {
        flex-shrink: 0;
      }
    }

    &-origin {
      position: absolute;
      left: 0;
      pointer-events: none;
    }
  }
}

@mixin color($theme, $fg, $bg) {
  .legacy-tooltip {
    background: map.get($bg, tooltip);

    &-title {
      color: map.get($fg, contrast);
      background: map.get($bg, tooltip-title);
    }

    &-item-name {
      color: map.get($fg, contrast);
    }
  }
}

@mixin typography($theme, $fb, $bg) {
  $typography-config: mat.m2-get-typography-config($theme);

  .tooltip-title {
    font-size: 16px;
  }
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fb, $bg);
  }
}
