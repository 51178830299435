@use '../mixins' as mixins;

.overlay {
  @include mixins.overlay()
}

.elevation {
  @include mixins.elevation()
}

.inset {
  position: absolute;
  inset: 0;
}

.centroid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text {
  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-left {
    text-align: left;
  }
}

.overflow {
  &-hidden {
    overflow: hidden;
  }

  &-auto {
    overflow: auto;
  }
}

//.relative {
//  position: relative;
//}

.absolute {
  position: absolute;
}
