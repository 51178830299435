@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme, $fg, $bg) {
  $success: map.get(mat.m2-get-color-config($fg), success);

  app-test-indicator {
    mat-icon{
      &.success {
        color: $success
      }
      &.error {
        color: $success
      }
    }
  }
}

@mixin typography($theme, $fb, $bg) {
  $typography-config: mat.m2-get-typography-config($theme);

  app-select-like-menu{
    .menu-trigger {
      @include mat.m2-typography-level($typography-config, caption);
    }
  }
}

@mixin theme($theme, $fb, $bg) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fb, $bg);
  }
}
